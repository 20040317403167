var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("picture", { ref: "lazyImg", staticClass: "lozad lazyCategory" }, [
    _c("source", {
      attrs: {
        "data-srcset": _vm.imageUrlMiddle + ".avif",
        media: "(min-width: 576px)",
        type: "image/avif"
      }
    }),
    _vm._v(" "),
    _c("source", {
      attrs: {
        "data-srcset": _vm.imageUrlMiddle + ".webp",
        media: "(min-width: 576px)",
        type: "image/webp"
      }
    }),
    _vm._v(" "),
    _c("source", {
      attrs: { "data-srcset": _vm.imageUrlMiddle, media: "(min-width: 576px)" }
    }),
    _vm._v(" "),
    _c("source", {
      attrs: {
        "data-srcset": _vm.imageUrlPreview + ".avif",
        media: "(min-width: 200px)",
        type: "image/avif"
      }
    }),
    _vm._v(" "),
    _c("source", {
      attrs: {
        "data-srcset": _vm.imageUrlPreview + ".webp",
        media: "(min-width: 200px)",
        type: "image/webp"
      }
    }),
    _vm._v(" "),
    _c("source", {
      attrs: { "data-srcset": _vm.imageUrlPreview, media: "(min-width: 200px)" }
    }),
    _vm._v(" "),
    _c("img", {
      staticClass: "img-fluid",
      attrs: {
        "data-src": _vm.imageUrlMiddle,
        alt: _vm.altText,
        width: "300",
        height: "300"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }