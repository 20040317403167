const browserDetect = require("detect-browser");
const NotificationService = require("./services/NotificationService");
// const AutoFocusService = require("./services/AutoFocusService");

// import { MediaQueryHelper } from "./helper/MediaQueryHelper";
// import { debounce } from "./helper/debounce";
import { detectPassiveEvents } from "./helper/featureDetect";

import Vue from "vue";

// Frontend end scripts
// eslint-disable-next-line

function CeresMain()
{
    const browser = browserDetect.detect();

    if (browser)
    {
        if (browser.name)
        {
            $("html").addClass(browser.name);
        }
        else
        {
            $("html").addClass("unknown-browser");
        }
        if (browser.os)
        {
            $("html").addClass(browser.os.toLowerCase().replace(/[^a-zA-Z0-9\-]/g, "-").replace("windows", "windows windows"));
        }
        else
        {
            $("html").addClass("unknown-os");
        }
    }
    else
    {
        $("html").addClass("unknown-detect");
    }

    // Detect Facebook integrated Browser
    if (typeof navigator !== "undefined" && /FBA[NV]\/([0-9\.]+)/.test(navigator.userAgent))
    {
        document.body.classList.add("facebook");
    }

    window.onpopstate = function(event)
    {
        if (event.state && event.state.requireReload)
        {
            window.location.reload();
        }
    };

    // init bootstrap tooltips
    document.querySelectorAll("[data-toggle=\"tooltip\"]").forEach(el =>
    {
        $(el).tooltip();
    });

    const $mainNavbarCollapse = $("#mainNavbarCollapse");

    $mainNavbarCollapse.collapse("hide");

    // Add click listener outside the navigation to close it
    $mainNavbarCollapse.on("show.bs.collapse", function()
    {
        $(".main").one("click", closeNav);
    });

    $mainNavbarCollapse.on("hide.bs.collapse", function()
    {
        $(".main").off("click", closeNav);
    });

    function closeNav()
    {
        $("#mainNavbarCollapse").collapse("hide");
    }

    $(document).ready(function()
    {
        const offset = 250;
        const duration = 300;

        let isDesktop = window.matchMedia("(min-width: 768px)").matches;

        // AutoFocusService.autoFocus();

        window.addEventListener("scroll", function()
        {
            if (isDesktop)
            {
                if ($(window).scrollTop() > offset)
                {
                    $(".back-to-top-center").fadeIn(duration);
                }
                else
                {
                    $(".back-to-top-center").fadeOut(duration);
                }
            }
        }, detectPassiveEvents() ? { passive: true } : false );

        window.addEventListener("resize", function()
        {
            isDesktop = window.matchMedia("(min-width: 768px)").matches;
        });

        $(".back-to-top-center").click(function(event)
        {
            event.preventDefault();

            $("html, body").animate({ scrollTop: 0 }, duration);

            return false;
        });
    });
}

window.CeresMain = new CeresMain();
window.CeresNotification = NotificationService;
// window.MediaQueryHelper = new MediaQueryHelper();
if (navigator.userAgent.indexOf("MSIE")!==-1
|| navigator.appVersion.indexOf("Trident/") > -1)
{
    /* Microsoft Internet Explorer detected in. */
    $(document).ready(function()
    {
        $(".app").prepend("<div class='notify'><p class='Message Text'>Nicht alle Funktionen unseres Shops werden von Ihrem Browser unterstützt.</p></div>");
    });
}

const showShopNotification = function(event)
{
    if (event.detail.type)
    {
        switch (event.detail.type)
        {
        case "info":
            NotificationService.info(event.detail.message);
            break;
        case "log":
            NotificationService.log(event.detail.message);
            break;
        case "error":
            NotificationService.error(event.detail.message);
            break;
        case "success":
            NotificationService.success(event.detail.message);
            break;
        case "warning":
            NotificationService.warn(event.detail.message);
            break;
        default:
            console.log("no type such as:" + event.detail.type);
            break;
        }
    }
};

document.addEventListener("showShopNotification", showShopNotification);

// remove header offset

$(document).on("shopbuilder.after.drop shopbuilder.after.widget_replace", function(event, eventData, widgetElement)
{
    let parent = widgetElement[1];

    let parentComponent = null;

    while (parent)
    {
        if (parent.__vue__)
        {
            parentComponent = parent.__vue__;
            break;
        }
        parent = parent.parentElement;
    }

    const compiled = Vue.compile(widgetElement[0].outerHTML, { delimiters: ["${", "}"] } );
    const component = new Vue({
        store: window.ceresStore,
        render: compiled.render,
        staticRenderFns: compiled.staticRenderFns,
        parent: parentComponent
    });

    component.$mount( widgetElement[0] );
    $(component.$el).find("*").each(function(index, elem)
    {
        $(elem).click(function(event)
        {
            event.preventDefault();
        });
    });
});
$(document).ready(function()
{
    $("#toggleFilter").on("click touch", function()
    {
        $(this).toggleClass("toggleFilter");
        $("#filterList").toggle();
        if ($(this).hasClass("toggleFilter"))
        {
            $(this).text("Filter Schließen");
        }
        else
        {
            $(this).text("Filter Öffnen");
        }
    });
    $("#openCookieAgain").on("click touch", function()
    {
        $(".consent-container").toggle();
        $("html,body").animate({ scrollTop: ($(".consent-container").first().offset().top) }, 500);
    });
    $(".description table").wrap("<div class=\"table-responsive\"></div>");
    $(".cat-description table").wrap("<div class=\"table-responsive\"></div>");
});
function changeCSS()
{
    const element = document.querySelector(".sa-badge");

    if (element !== null)
    {
        this.scrollY > 500 ? element.classList.remove("fix-badge") : element.classList.add("fix-badge");
    }
    // element.classList.remove('fix-badge');
}
function changeCSS2()
{
    const element = document.querySelector(".fo-badge");

    if (element !== null)
    {
        this.scrollY > 500 ? element.classList.remove("fix-fobadge") : element.classList.add("fix-fobadge");
    }
    // element.classList.remove('fix-badge');
}
function changeCSS3()
{
    const element = document.querySelector(".mobile-badge");

    if (element !== null)
    {
        this.scrollY > 500 ? element.classList.remove("mobile-badge") : element.classList.add("mobile-badge");
    }
    // element.classList.remove('fix-badge');
}

window.addEventListener("scroll", changeCSS, false);
window.addEventListener("scroll", changeCSS2, false);
window.addEventListener("scroll", changeCSS3, false);


