var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { "has-crossprice": _vm.hasCrossPrice } }, [
    _vm.showCrossPrice && _vm.hasCrossPrice
      ? _c(
          "div",
          {
            staticClass: "crossprice",
            class: { "is-special-offer": _vm.hasSpecialOffer }
          },
          [
            _c(
              "del",
              { staticClass: "small text-appearance text-danger" },
              [
                _vm.hasSpecialOffer
                  ? [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("itemCrossPrice")(
                              _vm.currentVariation.prices.default.unitPrice
                                .formatted,
                              true
                            )
                          ) +
                          "\n          "
                      )
                    ]
                  : [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("itemCrossPrice")(
                              _vm.currentVariation.prices.rrp.unitPrice
                                .formatted
                            )
                          ) +
                          "\n          "
                      )
                    ]
              ],
              2
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "span",
      {
        staticClass: "price h1",
        class: { "is-special-offer": _vm.hasSpecialOffer }
      },
      [
        _c("span", [
          _vm._v(
            "\n         " +
              _vm._s(
                _vm._f("currency")(
                  _vm.variationTotalPrice,
                  _vm.currentVariation.prices.default.currency
                )
              ) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("sup", [
          _vm._v(_vm._s(_vm.$translate("Ceres::Template.singleItemFootnote1")))
        ])
      ]
    ),
    _vm._v(" "),
    _vm.propertiesWithAdditionalCostsVisible.length
      ? _c(
          "ul",
          { staticClass: "text-muted pl-0 list-unstyled" },
          _vm._l(_vm.propertiesWithAdditionalCostsVisible, function(property) {
            return _c("li", { key: property.propertyId }, [
              _c(
                "span",
                { staticClass: "d-block" },
                [
                  _vm._v(
                    "\n                  " +
                      _vm._s(property.property.names.name) +
                      " "
                  ),
                  _vm.$options.filters.propertySurcharge(
                    _vm.currentVariation.properties,
                    property.propertyId
                  ) > 0
                    ? [
                        _vm._v(
                          "(" +
                            _vm._s(
                              _vm.$translate("Ceres::Template.basketPlusAbbr")
                            ) +
                            " " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm._f("propertySurcharge")(
                                  _vm.currentVariation.properties,
                                  property.propertyId
                                )
                              )
                            ) +
                            ")"
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasTax(property)
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "Ceres::Template.singleItemFootnote1"
                            )
                          )
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ])
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.currentVariation.prices.default.lowestPrice.value &&
    _vm.showCrossPrice &&
    _vm.hasCrossPrice
      ? _c("div", { staticClass: "lowest-price text-muted mb-3" }, [
          _c("div", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$translate("KrauseSohn::Template.singleItemLowestPrice")
                ) +
                ": "
            ),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.currentVariation.prices.default.lowestPrice.formatted
                )
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.currentVariation.unit
      ? _c(
          "div",
          {
            staticClass: "base-price text-muted",
            class: {
              "is-single-piece":
                _vm.currentVariation.unit &&
                _vm.currentVariation.unit.content === 1 &&
                _vm.currentVariation.unit.unitOfMeasurement === "C62"
            }
          },
          [
            _c("div", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$translate("Ceres::Template.singleItemContent")) +
                  "\n            "
              ),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("numberFormat")(_vm.currentVariation.unit.content)
                  ) + " "
                )
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.currentVariation.unit.names.name))])
            ]),
            _vm._v(" "),
            _vm.currentVariation.variation.mayShowUnitPrice
              ? _c("div", [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$translate("Ceres::Template.singleItemUnitPrice")
                      ) +
                      "\n            "
                  ),
                  _c("span", { staticClass: "base-price-value" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("specialOffer")(
                            _vm.variationGraduatedPrice.basePrice,
                            _vm.currentVariation.prices,
                            "basePrice"
                          )
                        ) +
                        "\n            "
                    )
                  ])
                ])
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }