var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isBackgroundImage
    ? _c(
        "picture",
        {
          attrs: {
            "data-iesrc": _vm.defaultImageUrl,
            "data-picture-class": _vm.pictureClass,
            "data-alt": _vm.alt,
            "data-title": _vm.title,
            id: _vm.uuid
          }
        },
        [
          _vm._t("additionalimages"),
          _vm._v(" "),
          _c("source", {
            attrs: { srcset: _vm.defaultImageUrl, type: _vm.mimeType }
          }),
          _vm._v(" "),
          _vm.defaultImageUrl !== _vm.imageUrl
            ? _c("source", { attrs: { srcset: _vm.imageUrl } })
            : _vm._e(),
          _vm._v(" "),
          _vm.fallbackUrl
            ? _c("source", { attrs: { srcset: _vm.fallbackUrl } })
            : _vm._e(),
          _vm._v(" "),
          _vm.receivedImageExtension === "tif"
            ? _c("img", {
                attrs: {
                  src: _vm.defaultImageUrl,
                  alt: _vm.alt,
                  type: "image/tiff"
                }
              })
            : _vm._e()
        ],
        2
      )
    : _c(
        "div",
        {
          class: _vm.pictureClass,
          attrs: {
            "data-background-image": _vm.defaultImageUrl || _vm.fallbackUrl
          }
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }