<template>
    <img :data-src="imageUrl" :alt="alt" :title="title" :class="classes" :width="width" :height="height">
</template>

<script>
export default {

  props:
      {
        imageUrl: "",
        title: "",
        alt: "",
        classes: "",
        width: "",
        height: ""
      },
  mounted()
  {
    this.$nextTick(() =>
    {
      if ("IntersectionObserver" in window)
      {
        const imageObserver = new IntersectionObserver((entry, imageObserver) => {
          if(entry[0].isIntersecting)
          {
            entry[0].target.src = entry[0].target.dataset.src;

            imageObserver.unobserve(entry[0].target);
          }
        });
        imageObserver.observe(this.$el);
      }
      else
      {
        const image = $(this.$el).find("img[data-src]");
        image.attr("src", image.data('src'));
        image.removeAttr("data-src");
      }
    });
  }
}
</script>
