var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "consent-container px-3 py-2 mx-auto bg-white " },
    [
      _c("p", { staticClass: "h3 consent-header" }, [
        _vm._v("Einwilligung zu Cookies und Daten")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "consent-content",
          class: { "d-none": _vm.settings, "d-block": !_vm.settings }
        },
        [_vm._t("cookie-text")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "consent-buttons my-2 text-center",
          class: { "d-none": _vm.settings, "d-block": !_vm.settings }
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-grey btn-large",
              attrs: { "aria-label": "Alle Cookies akzeptieren Button" },
              on: {
                click: function($event) {
                  return _vm.setAllCookies()
                }
              }
            },
            [_vm._v("\n      Alle Akzeptieren\n    ")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "consent-buttons my-2 text-center",
          class: { "d-none": _vm.settings, "d-block": !_vm.settings }
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-grey btn-large",
              attrs: {
                "aria-label": "Nur technisch notwendige Cookies akzeptieren"
              },
              on: {
                click: function($event) {
                  return _vm.setNoCookies()
                }
              }
            },
            [_vm._v("\n      Nur technisch notwendige Cookies Zulassen\n    ")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "consent-buttons my-2 text-center",
          class: { "d-none": _vm.settings, "d-block": !_vm.settings }
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-grey btn-large",
              attrs: { "aria-label": "Cookie Einstellungen ändern" },
              on: {
                click: function($event) {
                  return _vm.setSettings()
                }
              }
            },
            [_vm._v("\n    Einstellungen\n  ")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "consent-settings mt-2",
          class: { "d-bock": _vm.settings, "d-none": !_vm.settings }
        },
        [
          _c("table", { staticClass: "table table-striped" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("tbody", [
              _vm._m(1),
              _vm._v(" "),
              _vm.analytics
                ? _c("tr", [
                    _c("td", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "custom-control custom-switch d-md-inline-block mr-lg-3 mb-2 mb-lg-0"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.analyse,
                                expression: "analyse"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: { type: "checkbox", id: "customSwitch1" },
                            domProps: {
                              checked: Array.isArray(_vm.analyse)
                                ? _vm._i(_vm.analyse, null) > -1
                                : _vm.analyse
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.analyse,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.analyse = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.analyse = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.analyse = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "customSwitch1" }
                            },
                            [_vm._v("Google Analytics")]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "Anonyme Analyse der Userinteraktion mit unserer Webseite mit dem Tool Google Analytics"
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.marketing
                ? _c("tr", [
                    _c("td", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "custom-control custom-switch d-md-inline-block mr-lg-3 mb-2 mb-lg-0"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.werbung,
                                expression: "werbung"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: { type: "checkbox", id: "customSwitch2" },
                            domProps: {
                              checked: Array.isArray(_vm.werbung)
                                ? _vm._i(_vm.werbung, null) > -1
                                : _vm.werbung
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.werbung,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.werbung = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.werbung = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.werbung = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "customSwitch2" }
                            },
                            [_vm._v("Werbung")]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Platzhalter")])
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "consent-buttons my-2 mx-auto d-flex flex-row" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-grey w-100 ml-2",
                  on: {
                    click: function($event) {
                      return _vm.createCookie()
                    }
                  }
                },
                [_vm._v("\n        Einstellung speichern\n      ")]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "consent-links text-center" }, [
        _c(
          "a",
          {
            attrs: {
              href: _vm.legal,
              title:
                "Beim Klick wird die Einstellung nur notwendige Cookies angewendet"
            },
            on: {
              click: function($event) {
                return _vm.setNoCookies()
              }
            }
          },
          [_vm._v("Impressum")]
        ),
        _vm._v(" |\n    "),
        _c(
          "a",
          {
            attrs: {
              href: _vm.privacy,
              title:
                "Beim Klick wird die Einstellung nur notwendige Cookies angewendet"
            },
            on: {
              click: function($event) {
                return _vm.setNoCookies()
              }
            }
          },
          [_vm._v("Datenschutz")]
        ),
        _vm._v(" |\n    "),
        _c(
          "a",
          {
            attrs: {
              href: "/cookie",
              title:
                "Beim Klick wird die Einstellung nur notwendige Cookies angewendet"
            },
            on: {
              click: function($event) {
                return _vm.setNoCookies()
              }
            }
          },
          [_vm._v("Cookies")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Einstellung")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Zweck")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [
        _c(
          "span",
          {
            staticClass:
              "custom-control custom-switch d-md-inline-block mr-lg-3 mb-2 mb-lg-0"
          },
          [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                id: "technicalSwitch",
                checked: "",
                disabled: ""
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "technicalSwitch" }
              },
              [_vm._v("Technisch Notwendig")]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("\n          Bereitstellung der Shop Funktionen\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }