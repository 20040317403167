import Vue from "vue";

Vue.mixin({
    props: {
        templateOverride:{
            type: String,
            default: ""
        }
    },
    created()
    {
        if (this.$props && this.$props.template)
        {
            this.$options.template = this.$props.template;
        }
    }
});
