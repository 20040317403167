<template>
    <picture class="lozad lazyCategory" ref="lazyImg" >
      <source :data-srcset="imageUrlMiddle + '.avif'" media="(min-width: 576px)" type="image/avif">
      <source :data-srcset="imageUrlMiddle + '.webp'" media="(min-width: 576px)" type="image/webp">
      <source :data-srcset="imageUrlMiddle" media="(min-width: 576px)">
      <source :data-srcset="imageUrlPreview + '.avif'" media="(min-width: 200px)" type="image/avif">
      <source :data-srcset="imageUrlPreview + '.webp'" media="(min-width: 200px)" type="image/webp">
      <source :data-srcset="imageUrlPreview" media="(min-width: 200px)">
      <img class="img-fluid" :data-src="imageUrlMiddle" :alt="altText" width="300" height="300">
    </picture>
</template>
<script>
export default {

    props: {
        imageUrlPreview: {
            type: String,
            default: ""
        },
        imageUrlMiddle: {
            type: String,
            default: ""
        },
        altText: String,
        variationId:{
          default: 0
        }
    },

    mounted()
    {
        this.$nextTick(() =>
        {
          if ("IntersectionObserver" in window)
          {
            const imageObserver = new IntersectionObserver((entry, imageObserver) => {
              if(entry[0].isIntersecting)
              {
                entry[0].target.children.forEach((child) => {
                  if(child.tagName === "SOURCE")
                  {
                    child.srcset = child.dataset.srcset;
                  }
                  if(child.tagName === "IMG")
                  {
                    child.src = child.dataset.src;
                  }
                });
                imageObserver.unobserve(entry[0].target);
              }
              });
            imageObserver.observe(this.$el);
          }
          else
          {
            console.log("Your Browser is to old!");
            const image = $(this.$el).find("img[data-src]");
            image.attr("src", image.data('src'));
            image.removeAttr("data-src");
          }
        });
    }
}
</script>
