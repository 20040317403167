import Vue from "vue";

// =========================
// COMPONENTS
// =========================

// BASE
import LazyImg from "./app/components/common/LazyImg.vue";
import LazySingleImg from "./app/components/common/LazySingleImg.vue";
import Intersect from "./app/components/common/Intersect.vue";
//import TabList from "./app/components/common/TabList.vue";
//import TabItem from "./app/components/common/TabItem.vue";
import LazyLoad from "./app/components/common/LazyLoad.vue";

//import ReCaptcha from "./app/components/customer/ReCaptcha.vue";
// legacy non-shopbuilder component
import UserLoginHandler from "./app/components/customer/login/UserLoginHandler.vue";

//import CategoryItem from "./app/components/itemList/CategoryItem.vue";
import ItemSearch from "./app/components/itemList/ItemSearch.vue";

//import CookieBar from "./app/components/pageDesign/CookieBar.vue";
//import Carousel from "./app/components/pageDesign/Carousel.vue";
import Icon from "./app/components/pageDesign/Icon.vue";
// import MobileNavigation from "./app/components/pageDesign/MobileNavigation.vue";
import Notifications from "./app/components/pageDesign/Notifications.vue";
// import Popper from "./app/components/pageDesign/Popper.vue";
import LoadingAnimation from "./app/components/pageDesign/LoadingAnimation.vue";

//import WishListCount from "./app/components/wishList/WishListCount.vue";

import SingleItem from "./app/components/item/SingleItem.vue";
import ReCaptcha from "./app/components/customer/ReCaptcha.vue";

// import SingleItemSetComponent from "./app/components/item/SingleItemSetComponent.vue";

// EXTERNAL
import LazyHydrate from "vue-lazy-hydration";
import ClientOnly from "./app/components/common/ClientOnly.vue";
import script2 from "./app/plugins/script2";

// CHECKOUT
// import "./app/components/checkout/AcceptGtcCheck";
// import "./app/components/checkout/Checkout";
// import "./app/components/checkout/ContactWishInput";
// import "./app/components/checkout/CustomerSignInput";
// import "./app/components/checkout/PaymentProviderSelect";
// import "./app/components/checkout/PlaceOrder";
// import "./app/components/checkout/ShippingPrivacyHintCheck.vue";
// import "./app/components/checkout/ShippingProfileSelect";
// import "./app/components/checkout/SubscribeNewsletterCheck";

// import "./app/components/customer/AddressSelect/AddressHeader.vue";
// import "./app/components/customer/AddressSelect/InvoiceAddressSelect.vue";
// import "./app/components/customer/AddressSelect/ShippingAddressSelect.vue";

// import "./app/components/myAccount/AccountSettings";
// import "./app/components/myAccount/BankDataSelect";
// import "./app/components/myAccount/ChangePaymentMethod";
// import "./app/components/myAccount/MyAccount";
// import "./app/components/myAccount/OrderDocuments";
// import "./app/components/myAccount/History";

// // new shopbuilder-only component
// import "./app/components/myAccount/OrderHistoryList";
//import "./app/components/myAccount/OrderReturnHistoryList";

// =========================
// KrauseSohn Components
// =========================
import CookieConsent from "./app/components/pageDesign/CookieConsent.vue";
import ItemLazyImg from "./app/components/itemList/ItemLazyImg.vue";
import NotificationMessage from "./app/components/pageDesign/NotificationMessage.vue";
// import SingleAddToBasket from "./app/components/item/SingleAddToBasket.vue";

// import CheckAge from "./app/components/checkout/CheckAge.vue";
// import PickUpOptIn from "./app/components/checkout/PickUpOptIn.vue";
// import ShippingNotice from "./app/components/checkout/ShippingNotice.vue";



// =========================
// SERVICES
// =========================
import TranslationService from "./app/services/TranslationService";

// =========================
// MIXINS
// =========================
import "./app/mixins/template.mixin";
import "./app/mixins/getJsonData.mixin";

// =========================
// DIRECTIVES
// =========================
import "./app/directives/basket/basketItemQuantity";
import "./app/directives/basket/basketItemSum";
import "./app/directives/basket/toggleBasketPreview";
//import "./app/directives/category/openFilterToolbar";
import "./app/directives/common/truncateTooltip";
import "./app/directives/customer/logout";
import "./app/directives/helper/populateStore";
import "./app/directives/helper/validate";
import "./app/directives/helper/waitingAnimation";
import "./app/directives/helper/waitingAnimationInfinite";
import "./app/directives/navigation/navigationTouchHandler";
import "./app/directives/navigation/openMobileNavigation";
import "./app/directives/pageDesign/scrollToTop";
import "./app/directives/pageDesign/stickInParent";
//import "./app/directives/navigation/sidenavigationChildrenLoader";/
import "./app/directives/pageDesign/tooltip";


// =========================
// FILTERS
// =========================
import "./app/filters/ageRestriction.filter";
//import "./app/filters/arrayFirst.filter";
//import "./app/filters/attachText.filter";
import "./app/filters/currency.filter";
import "./app/filters/date.filter";
import "./app/filters/fileName.filter";
import "./app/filters/fileUploadPath.filter";
import "./app/filters/graduatedPrice.filter";
import "./app/filters/hasItemDefaultPrice.filter";
import "./app/filters/inputUnit.filter";
import "./app/filters/itemBundleName.filter";
import "./app/filters/itemCrossPrice.filter";
import "./app/filters/itemImage.filter";
import "./app/filters/itemImageAlternativeText.filter";
import "./app/filters/itemImages.filter";
import "./app/filters/itemName.filter";
import "./app/filters/itemPrice.filter";
import "./app/filters/itemUrl.filter";
import "./app/filters/numberFormat.filter";
import "./app/filters/propertySurcharge.filter";
import "./app/filters/propertyFileUrl.filter";
import "./app/filters/translate.filter";
import "./app/filters/truncate.filter";

export function beforeCreate(context)
{
    // =========================
    // COMPONENTS
    // =========================

    // BASE
    Vue.component("add-to-basket", () => import("./app/components/basket/AddToBasket.vue"));
    Vue.component("basket-preview", () => import("./app/components/basket/BasketPreview.vue"));
    Vue.component("basket-totals", () => import("./app/components/basket/BasketTotals.vue"));
    Vue.component("coupon", () => import("./app/components/basket/Coupon.vue"));
    Vue.component("basket-list", () => import("./app/components/basket/list/BasketList.vue"));
    Vue.component("lazy-img", LazyImg);
    Vue.component("lazy-single-img", LazySingleImg);
    Vue.component("recaptcha", ReCaptcha);
    Vue.component("intersect", Intersect);
    Vue.component("user-login-handler", UserLoginHandler);
    Vue.component("quantity-input", () => import("./app/components/item/QuantityInput.vue"));
    Vue.component("item-search", ItemSearch);
    Vue.component("search-suggestion-item", () => import("./app/components/itemList/SearchSuggestionItem.vue"));
    Vue.component("item-filter-list", () => import("./app/components/itemList/filter/ItemFilterList.vue"));
    Vue.component("newsletter-input", () => import("./app/components/newsletter/NewsletterInput.vue"));
    Vue.component("newsletter-unsubscribe-input", () => import("./app/components/newsletter/NewsletterUnsubscribeInput.vue"));
    Vue.component("cookie-consent", CookieConsent);
    Vue.component("icon", Icon);
    Vue.component("mobile-navigation", () => import("./app/components/pageDesign/MobileNavigation.vue"));
    Vue.component("notifications", Notifications);
    // Vue.component("popper", Popper);
    Vue.component("loading-animation", LoadingAnimation);
    Vue.component("lazy-load", LazyLoad);
    Vue.component("graduated-prices", () => import("./app/components/item/GraduatedPrices.vue"));
    Vue.component("item-image-carousel", () => import("./app/components/item/ItemImageCarousel.vue"));
    Vue.component("order-property-list", () => import("./app/components/item/OrderPropertyList.vue"));
    Vue.component("variation-select", () => import("./app/components/item/VariationSelect.vue"));
    Vue.component("single-item", SingleItem);
    Vue.component("client-only", ClientOnly);

    //Krausesohn
    Vue.component("item-lazy-img", ItemLazyImg);
    Vue.component("notification-message", NotificationMessage);
    Vue.component("ks-timer", () => import("./app/components/item/TimerKS.vue"));
    Vue.component("variation-description", () => import("./app/components/item/VariationDescription.vue"));
    Vue.component("variation-properties-ks", () => import("./app/components/item/VariationProperties.vue"));
    Vue.component("shipping-vat-info", () => import("./app/components/item/ShippingVatInfo.vue"));
    Vue.component("lazy-youtube", () => import("./app/components/item/LazyYoutube.vue"));
    Vue.component("lazy-picture", () => import("./app/components/common/LazyPicture.vue"));

    Vue.component("my-newsletter-form", () => import("./app/components/newsletter/MyNewsletterForm.vue"));

    // EXTERNAL
    Vue.component("lazy-hydrate", LazyHydrate);
    Vue.use(script2);

    Vue.prototype.$translate = TranslationService.translate;
    Vue.prototype.$ceres = App;
}

export function createApp(options, store)
{

    const defaultOptions = {
        store,
        ...options
    };

    const app = new Vue(defaultOptions);

    return app ;
}
