<template>
  <div class="position-relative">
      <div class="dropdown" v-if="isLoggedIn || twigIsLoggedIn">
          <a href="#" class="dropdown-toggle" id="accountMenuList" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-user mr-0 mr-sm-1" aria-hidden="true"></i><br/>
              <span class="d-xs-none small">Hallo {{ usernameToShow }}</span>
          </a>
          <div class="dropdown-menu small m-0 p-0 mw-100">
            <div class="list-group" aria-labelledby="accountMenuList" >
              <a :href="myAccountUrl" class="list-group-item small"><i class="fa fa-user"></i>  Mein Konto</a>
              <a href="#" class="list-group-item small" v-logout><i class="fa fa-sign-out"></i> Ausloggen</a>
            </div>
          </div>
      </div>
      <div v-if="!isLoggedIn && !twigIsLoggedIn">
          <a :href="isLogin ? 'javascript:void(0)' : '/login?g=0'" :aria-label="$translate('Ceres::Template.login')">
              <i class="fa fa-user mr-0 mr-sm-1" aria-hidden="true"></i><br/>
              <span class="d-none d-sm-inline">Anmelden</span>
          </a>
      </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default  {

    props: {
        showRegistration: {
            type: Boolean,
            default: true
        },
      twigUsername: {
          type: String,
        default: ""
      },
      twigIsLoggedIn: {
          type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapGetters([
          "username",
          "isLoggedIn"
      ]),
      usernameToShow()
      {
        return this.twigUsername.length > 0 ? this.twigUsername : this.username;
      },
      myAccountUrl()
      {
        return App.urls.myAccount;
      }
    },

    data()
    {
           return{
            isLogin: App.templateType === "login",
            isRegister: App.templateType === "register"
           }
    }
}
</script>
