var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSSREnabled
    ? _c(
        "lazy-hydrate",
        { attrs: { "when-visible": _vm.intersectionObserverOptions } },
        [_vm._t("default")],
        2
      )
    : _vm.isIntersectorEnabled
    ? _c("intersector", [_vm._t("default")], 2)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }