var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    class: _vm.classes,
    attrs: {
      "data-src": _vm.imageUrl,
      alt: _vm.alt,
      title: _vm.title,
      width: _vm.width,
      height: _vm.height
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }