var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "single container-max page-content" },
    [
      _c(
        "div",
        { staticClass: "row mt-5 position-relative" },
        [
          _vm._t("image-carousel"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-5 single-rightside my-2" },
            [
              _c("variation-description", {
                attrs: {
                  element: "h1",
                  texts: _vm.currentVariation.texts.name1,
                  properties: _vm.currentVariation.variationProperties
                }
              }),
              _vm._v(" "),
              _vm.currentVariation.texts.shortDescription !== ""
                ? _c("p", { staticClass: "single-description" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.currentVariation.texts.shortDescription
                        )
                      }
                    }),
                    _vm._v(" "),
                    _vm.currentVariation.attributes[0]
                      ? _c("span", [
                          _vm._v(
                            "\n               | " +
                              _vm._s(
                                _vm.currentVariation.attributes[0].attribute
                                  .names.name
                              ) +
                              " " +
                              _vm._s(
                                _vm.currentVariation.attributes[0].value.names
                                  .name
                              )
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-5" },
                [
                  _c(
                    "span",
                    { staticClass: "articlenumber small text-muted" },
                    [
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm.$translate("Ceres::Template.singleItemNumber")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.currentVariation.variation.number))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.bullet
                    ? _vm._l(_vm.bullet, function(bullet, index) {
                        return _c("variation-properties-ks", {
                          key: index,
                          attrs: { bullet: bullet }
                        })
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._t("video-link-container")
                ],
                2
              ),
              _vm._v(" "),
              _vm.attributes.length || _vm.units.length
                ? _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _vm.attributes.length || _vm.units.length
                        ? _c("variation-select")
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._t("before-price"),
              _vm._v(" "),
              _vm.currentVariation.filter.isSalable &&
              _vm.variationGroupedProperties.length
                ? _c("order-property-list")
                : _vm._e(),
              _vm._v(" "),
              _c("graduated-prices"),
              _vm._v(" "),
              _c("item-price"),
              _vm._v(" "),
              _vm._t("after-price"),
              _vm._v(" "),
              !_vm.isFirework || (_vm.isFirework && !_vm.isFireworkPickupOnly)
                ? _c(
                    "span",
                    {
                      staticClass: "availability badge rounded py-1",
                      class: _vm.classes
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n            " + _vm._s(_vm.name) + "\n        "
                        )
                      ])
                    ]
                  )
                : _vm.isFirework &&
                  _vm.isFireworkPickupOnly &&
                  !(_vm.currentVariation.variation.availability.id > 1)
                ? _c(
                    "span",
                    {
                      staticClass:
                        "availability badge rounded py-1 availability-3"
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$translate(
                                "KrauseSohn::Template.itemNoDeliveryForFirework"
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentVariation.stock && _vm.getShowStock
                ? [
                    _vm.getStockIndicator == 2
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "availability badge small availability-3 px-2 py-1"
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Nur noch " +
                                  _vm._s(_vm.currentVariation.stock.net) +
                                  " auf Lager"
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getStockIndicator == 1
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "availability badge small availability-1 px-2 py-1"
                          },
                          [_c("span", [_vm._v("Auf Lager")])]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getStockIndicator == 3
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "availability badge small availability-7 px-2 py-1"
                          },
                          [_c("span", [_vm._v("Nicht auf Lager")])]
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm._t("firework-min-shipping-info"),
              _vm._v(" "),
              _c("div", { staticClass: "my-3" }, [
                _c(
                  "div",
                  { staticClass: "w-100" },
                  [_vm._t("before-add-to-basket")],
                  2
                ),
                _vm._v(" "),
                _vm.currentVariation.item.itemType === "set"
                  ? _c("div", { staticClass: "alert alert-info w-100" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$translate("Ceres::Template.singleItemSetInfo")
                          ) +
                          "\n        "
                      )
                    ])
                  : _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c("add-to-basket", {
                          attrs: {
                            "variation-id": _vm.currentVariation.variation.id,
                            "is-salable":
                              !!_vm.currentVariation.filter &&
                              _vm.currentVariation.filter.isSalable &&
                              !_vm.isCat2Disabled,
                            "has-children":
                              !!_vm.currentVariation.filter &&
                              _vm.currentVariation.filter.hasActiveChildren,
                            "interval-quantity":
                              _vm.currentVariation.variation
                                .intervalOrderQuantity || 1,
                            "minimum-quantity":
                              _vm.currentVariation.variation
                                .minimumOrderQuantity,
                            "maximum-quantity":
                              !!_vm.currentVariation.variation
                                .maximumOrderQuantity &&
                              _vm.currentVariation.variation
                                .maximumOrderQuantity > 0
                                ? _vm.currentVariation.variation
                                    .maximumOrderQuantity
                                : null,
                            "order-properties": _vm.currentVariation.properties.filter(
                              function(prop) {
                                return prop.property.isOderProperty
                              }
                            ),
                            "use-large-scale": false,
                            "show-quantity": true,
                            "item-url": _vm._f("itemURL")(_vm.currentVariation),
                            "is-variation-selected":
                              _vm.isVariationSelected &&
                              _vm.currentVariation.filter.isSalable,
                            "has-price": _vm._f("hasItemDefaultPrice")(
                              _vm.currentVariation
                            )
                          }
                        })
                      ],
                      1
                    ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-100" },
                  [_vm._t("after-add-to-basket")],
                  2
                )
              ]),
              _vm._v(" "),
              _vm.currentVariation.filter.isSalable
                ? _vm._t("countdown")
                : _vm._e(),
              _vm._v(" "),
              _vm._t("additional-content-after-add-to-basket"),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("shipping-vat-info"),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm._t("additional-content-after-vat")
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c(
              "ul",
              { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
              [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link active",
                      attrs: {
                        "data-toggle": "tab",
                        href: "#details-" + _vm.currentVariation.variation.id,
                        role: "tab"
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$translate(
                              "Ceres::Template.singleItemDescription"
                            )
                          ) +
                          "\n          "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        "data-toggle": "tab",
                        href: "#feedback",
                        role: "tab"
                      }
                    },
                    [
                      _vm._v("\n            Bewertung\n            "),
                      _vm.currentVariation.item.feedbackCount > 0
                        ? _c("span", [
                            _vm._v(
                              "\n                        ( " +
                                _vm._s(
                                  _vm.currentVariation.item.feedbackCount
                                ) +
                                " )\n                      "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._t("add-detail-tabs")
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tab-content" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "tab-pane description active overflow-auto p-2",
                    attrs: {
                      id: "details-" + _vm.currentVariation.variation.id,
                      role: "tabpanel"
                    }
                  },
                  [
                    _c("variation-description", {
                      attrs: {
                        element: "div",
                        texts: _vm.currentVariation.texts.description,
                        properties: _vm.currentVariation.variationProperties
                      }
                    }),
                    _vm._v(" "),
                    _vm._t("video-container")
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane",
                    attrs: { id: "feedback", role: "tabpanel" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "my-4" },
                      [_vm._t("feedback-container")],
                      2
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane",
                    attrs: { id: "safety", role: "tabpanel" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "my-4" },
                      [_vm._t("manufacturer")],
                      2
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._t("add-detail-tabs-content")
              ],
              2
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("cross-selling-content"),
      _vm._v(" "),
      _vm._t("item-list-container")
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: { "data-toggle": "tab", href: "#safety", role: "tab" }
        },
        [_vm._v("\n            Angaben zur Produktsicherheit\n          ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }