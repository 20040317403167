<template>
  <div class="consent-container px-3 py-2 mx-auto bg-white " >
    <p class="h3 consent-header">Einwilligung zu Cookies und Daten</p>
    <div class="consent-content" :class="{ 'd-none': settings, 'd-block': !settings }">
      <slot name="cookie-text" />
    </div>
    <div class="consent-buttons my-2 text-center" :class="{ 'd-none': settings, 'd-block': !settings }">
      <button class="btn btn-grey btn-large" @click="setAllCookies()" aria-label="Alle Cookies akzeptieren Button">
        Alle Akzeptieren
      </button>
    </div>
    <div class="consent-buttons my-2 text-center" :class="{ 'd-none': settings, 'd-block': !settings }">
      <button class="btn btn-grey btn-large" @click="setNoCookies()" aria-label="Nur technisch notwendige Cookies akzeptieren">
        Nur technisch notwendige Cookies Zulassen
      </button>
    </div>
    <div class="consent-buttons my-2 text-center" :class="{ 'd-none': settings, 'd-block': !settings }">
    <button class="btn btn-grey btn-large" @click="setSettings()" aria-label="Cookie Einstellungen ändern">
      Einstellungen
    </button>
    </div>
    <div class="consent-settings mt-2" :class="{ 'd-bock': settings, 'd-none': !settings }">
      <table class="table table-striped" >
        <thead>
        <tr>
          <th scope="col">Einstellung</th>
          <th scope="col">Zweck</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <span class="custom-control custom-switch d-md-inline-block mr-lg-3 mb-2 mb-lg-0">
              <input type="checkbox" class="custom-control-input" id="technicalSwitch" checked disabled>
              <label class="custom-control-label" for="technicalSwitch">Technisch Notwendig</label>
            </span>
          </td>
          <td>
            Bereitstellung der Shop Funktionen
          </td>
        </tr>
        <tr v-if="analytics">
          <td>
            <span class="custom-control custom-switch d-md-inline-block mr-lg-3 mb-2 mb-lg-0" >
              <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="analyse">
              <label class="custom-control-label" for="customSwitch1">Google Analytics</label>
            </span>
          </td>
          <td>Anonyme Analyse der Userinteraktion mit unserer Webseite mit dem Tool Google Analytics</td>
        </tr>
        <tr v-if="marketing">
          <td >
            <span class="custom-control custom-switch d-md-inline-block mr-lg-3 mb-2 mb-lg-0" >
              <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="werbung">
              <label class="custom-control-label" for="customSwitch2">Werbung</label>
            </span>
          </td>
          <td>Platzhalter</td>
        </tr>
        </tbody>
      </table>
      <div class="consent-buttons my-2 mx-auto d-flex flex-row">
        <button class="btn btn-grey w-100 ml-2" @click="createCookie()">
          Einstellung speichern
        </button>
      </div>
    </div>
    <div class="consent-links text-center">
      <a :href="legal" @click="setNoCookies()" title="Beim Klick wird die Einstellung nur notwendige Cookies angewendet">Impressum</a> |
      <a :href="privacy" @click="setNoCookies()" title="Beim Klick wird die Einstellung nur notwendige Cookies angewendet">Datenschutz</a> |
      <a href="/cookie" @click="setNoCookies()" title="Beim Klick wird die Einstellung nur notwendige Cookies angewendet">Cookies</a>
    </div>
  </div>
</template>
<script>
import Cookie from "js-cookie";

export default
{
  name: "cookie-consent",
  props:{
    analytics: {
      type: Boolean,
      default: false
    },
    marketing: {
      type: Boolean,
      default: false
    },
    cookieName:{
      type: String,
      default: ""
    },
    googleAnalytics: {
      type: String
    }
  },
  data(){
    return {
      settings: false,
      analyse: false,
      werbung: false,
    }
  },
  computed:{
    showMarketing(){
      return this.marketing;
    },
    showAnalytics(){
      return this.analytics;
    },
    legal()
    {
      return App.urls.legalDisclosure;
    },
    privacy()
    {
      return App.urls.privacyPolicy;
    }
  },
  mounted()
      {
        this.$nextTick(() =>
        {
          if (this.getCookies())
          {
            this.analyse = this.getCookies().analytics;
            if (this.analyse == 1)
            {
              this.loadGoogle();
            }
            /*
            this.werbung = this.getCookies().adwords;
            if (this.werbung == 1)
            {
              this.loadGoogle();
            }*/
          }
          else
          {
            $(".consent-container").show();
          }
        });
      },
  methods:
      {
        getCookies()
        {
          if (Cookie.get(this.cookieName))
          {
            return Cookie.getJSON(this.cookieName);
          }
          return null;
        },
        createCookie()
        {
          const cookie = {
            analytics: this.analyse,
            adwords: this.werbung,
            optIntime: Date.now()
          };

          Cookie.set(this.cookieName, cookie, { expires: 180, sameSite: 'Lax', secure: true });
          $(".consent-container").slideUp();
          this.showContainer = false;
        },
        setNoCookies()
        {
          this.removeGoogleCookie();
          this.analyse = false;
          this.werbung = false;
          this.createCookie();
        },
        setAllCookies()
        {
          this.analyse = true;
          this.werbung = true;
          this.createCookie();
        },
        setSettings()
        {
          if(this.settings === false)
          {
            this.settings = true;
            return;
          }
          if(this.settings === true)
          {
            this.settings = false;
            return;
          }
        },
        removeGoogleCookie()
        {
          const google1 = "_ga";
          const google2 = "_gid";
          const google3 = "_gat_gtag_" + this.googleAnalytics;

          Cookie.remove(google1, { path: "/", domain:  "." + location.hostname });
          Cookie.remove(google2, { path: "/", domain:  "." + location.hostname });
          Cookie.remove(google3, { path: "/", domain:  "." + location.hostname });
        },
        loadGoogle()
        {
          if(!App.analyticsLoaded)
          {
            if(typeof this.googleAnalytics === "string")
            {
              const jsG = document.createElement("script");

              jsG.type="text/javascript";
              jsG.src="https://www.googletagmanager.com/gtag/js?id="+this.googleAnalytics;
              $("head").append(jsG);
              App.analyticsLoaded = true;
            }
          }
        }
      },
    watch:
      {
        analyse(val)
        {
          if (val == false)
          {
            this.removeGoogleCookie();
          }
          if(val == true)
          {
            this.loadGoogle();
          }
        }
      },
}
</script>
