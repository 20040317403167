<template>
  <div>
    <div class="notify row" v-show="showBanner & isActive">
        <div class="message text" v-html="dynContent"></div>
        <div class="button-container">
          <button type="button" aria-label="Close" class="close close-top" @click="setStorage()"><span aria-hidden="true">x</span></button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "notification-message",
  props:{
    content: {
      type: String
    }
  },
  data(){
    return {
      showBanner: false
    }
  },
  computed: {
    isActive()
    {
      return App.configKS.message.showNotifyText == 'true' ? true : false;
    },
    dynContent()
    {
      return App.configKS.message.notifyText;
    }
  },
  mounted()
  {
    this.$nextTick(() =>
    {
      this.getStorage();
    });
  },
  methods:{
    setStorage()
    {
      let obj = { value: false, timestamp: new Date().getTime() };
      localStorage.setItem("showNotifyBanner", JSON.stringify(obj));
      this.showBanner = false;
    },
    getStorage()
    {

      let object = JSON.parse(localStorage.getItem("showNotifyBanner"));
      if (object)
      {
        let now = new Date();
        let timestamp = new Date(object.timestamp);
        const today = now.getDate(); // get Day
        const dayTimestamp = timestamp.getDate();

        if ((today > dayTimestamp) && (object.value == false))
        {
          this.showBanner = true;
        }
        else
        {
          this.showBanner = false;
        }
      }
      else
      {
        this.showBanner = true;
      }

    }
  }
};
</script>
