<template>
  <div class="single container-max page-content">
    <div class="row mt-5 position-relative">
        <slot name="image-carousel" />
      <div class="col-md-5 single-rightside my-2">
        <!--Details-->
        <!-- ITEM DETAIL -->
        <variation-description element="h1" :texts="currentVariation.texts.name1" :properties="currentVariation.variationProperties"></variation-description>
        <p class="single-description" v-if="currentVariation.texts.shortDescription !== ''">
          <span v-html="currentVariation.texts.shortDescription"></span>
          <span v-if="currentVariation.attributes[0]">
                &nbsp;|&nbsp;{{currentVariation.attributes[0].attribute.names.name}}&nbsp;{{currentVariation.attributes[0].value.names.name}}</span>
        </p>
        <hr>
        <div class="mb-5">
                    <span class="articlenumber small text-muted">
                        <b>{{ $translate("Ceres::Template.singleItemNumber") }}</b>
                        <span>{{ currentVariation.variation.number }}</span>
                    </span>
          <br>
          <template v-if="bullet">
            <variation-properties-ks  v-for="(bullet, index) in bullet"
                                      :key="index"
                                      :bullet="bullet"
            >
            </variation-properties-ks>
          </template>
          <slot name="video-link-container" />
        </div>
        <!-- Variation -->
        <div class="mb-3" v-if="attributes.length || units.length">
          <variation-select v-if="attributes.length || units.length"></variation-select>
        </div>
        <!-- /Variation -->
        <slot name="before-price" />
        <order-property-list v-if="currentVariation.filter.isSalable && variationGroupedProperties.length"></order-property-list>
        <graduated-prices></graduated-prices>

        <!-- item-price -->
        <item-price></item-price>

        <!-- item-price -->

        <slot name="after-price" />

        <!-- item-availability  -->
        <!-- item-availability v-if="!isFirework || (isFirework && !isFireworkPickupOnly)"></item-availability -->
        <span v-if="!isFirework || (isFirework && !isFireworkPickupOnly)" class="availability badge rounded py-1"
              :class="classes">
          <span>
              {{ name }}
          </span>
        </span>
        <span class="availability badge rounded py-1 availability-3" v-else-if="(isFirework && isFireworkPickupOnly) && !( currentVariation.variation.availability.id > 1)">
          <span>
            {{ $translate('KrauseSohn::Template.itemNoDeliveryForFirework') }}
          </span>
        </span>
        <!-- item-availability -->

        <!-- Stock -->
        <template v-if="currentVariation.stock && getShowStock">
          <span class="availability badge small availability-3 px-2 py-1" v-if="getStockIndicator == 2">
            <span>Nur noch {{ currentVariation.stock.net }} auf Lager</span>
          </span>
          <span class="availability badge small availability-1 px-2 py-1" v-if="getStockIndicator == 1">
            <span>Auf Lager</span>
          </span>
          <span class="availability badge small availability-7 px-2 py-1" v-if="getStockIndicator == 3">
            <span>Nicht auf Lager</span>
          </span>
        </template>
        <slot name="firework-min-shipping-info" />

        <div class="my-3">
          <div class="w-100">
            <slot name="before-add-to-basket" />
          </div>
          <div v-if="currentVariation.item.itemType === 'set'" class="alert alert-info w-100">
            {{ $translate("Ceres::Template.singleItemSetInfo") }}
          </div>
          <div class="w-100" v-else>
            <!-- Basket Button -->
            <add-to-basket
                :variation-id="currentVariation.variation.id"
                :is-salable="!!currentVariation.filter && currentVariation.filter.isSalable && !isCat2Disabled"
                :has-children="!!currentVariation.filter && currentVariation.filter.hasActiveChildren"
                :interval-quantity="currentVariation.variation.intervalOrderQuantity || 1"
                :minimum-quantity="currentVariation.variation.minimumOrderQuantity"
                :maximum-quantity="!!currentVariation.variation.maximumOrderQuantity && currentVariation.variation.maximumOrderQuantity > 0 ? currentVariation.variation.maximumOrderQuantity : null"
                :order-properties="currentVariation.properties.filter(function(prop) { return prop.property.isOderProperty })"
                :use-large-scale="false"
                :show-quantity="true"
                :item-url="currentVariation | itemURL"
                :is-variation-selected="isVariationSelected && currentVariation.filter.isSalable"
                :has-price="currentVariation | hasItemDefaultPrice"
            >
            </add-to-basket>
          </div>
          <div class="w-100">
            <slot name="after-add-to-basket" />
          </div>
        </div>
        <slot name="countdown" v-if="currentVariation.filter.isSalable" />
        <slot name="additional-content-after-add-to-basket" />
        <hr>
        <shipping-vat-info></shipping-vat-info>
        <hr>
        <slot name="additional-content-after-vat" />
        <!-- ./ITEM DETAIL -->
      </div>
      <div class="col-md-7">
        <!-- ITEM DESCRIPTION -->
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" :href="'#details-' +  currentVariation.variation.id" role="tab" >
              {{ $translate("Ceres::Template.singleItemDescription") }}
            </a>
          </li>
          <li class="nav-item" >
            <a class="nav-link"  data-toggle="tab" href="#feedback" role="tab" >
              Bewertung
              <span v-if="currentVariation.item.feedbackCount > 0">
                          ( {{ currentVariation.item.feedbackCount }} )
                        </span>
            </a>
          </li>
          <li class="nav-item" >
            <a class="nav-link"  data-toggle="tab" href="#safety" role="tab" >
              Angaben zur Produktsicherheit
            </a>
          </li>
          <slot name="add-detail-tabs"></slot>
        </ul>
        <div class="tab-content">
          <div class="tab-pane description active overflow-auto p-2" :id="'details-' + currentVariation.variation.id" role="tabpanel">
            <variation-description element="div" :texts="currentVariation.texts.description"  :properties="currentVariation.variationProperties"></variation-description>
            <slot name="video-container" />

          </div>
          <div class="tab-pane" id="feedback" role="tabpanel">
            <div class="my-4" >
              <slot name="feedback-container" />
            </div>
          </div>
          <div class="tab-pane" id="safety" role="tabpanel">
            <div class="my-4" >
              <slot name="manufacturer" />
            </div>
          </div>
          <slot name="add-detail-tabs-content" />
        </div>
        <!-- ./ITEM DESCRIPTION -->
      </div>
    </div>
    <slot name="cross-selling-content" />
    <slot name="item-list-container" />
  </div>
</template>
<script>
import { get } from "../../helper/get";
import ItemPrice from "./ItemPrice.vue";

/**
 * Todo: Add Bullet Points for Items
 * */
export default {

    name: "single-item",
  components: {
    ItemPrice
  },

    props:
    {
      bullet: [Object, Array],
      showStock: {
        type: Boolean,
        default: true
      },
      itemId: {
            type: Number,
            required: true
        },
        afterKey: Object,
      isCat2Disabled: {
        type: Boolean,
        default: false
      },
      isFirework:{
        type: Boolean,
        default: false
      },
      stockNet: Number,
      itemData: [ Object, Array ],
      attributesData: [ Object, Array ],
      variations: [ Object, Array ]
    },

    provide()
    {
        return {
            itemId: this.$props.itemId
        }
    },

    computed:
    {
      // item-price
          hasCrossPrice() {
            const hasRrpPrice = !!this.currentVariation.prices.rrp &&
                this.currentVariation.prices.rrp.unitPrice.value > this.currentVariation.prices.default.unitPrice.value;

            const hasBeforePrice = this.hasSpecialOffer &&
                !!this.currentVariation.prices.default &&
                this.currentVariation.prices.default.unitPrice.value > this.currentVariation.prices.specialOffer.unitPrice.value;

            return hasRrpPrice || hasBeforePrice;
          },
          hasSpecialOffer() {
            return !!this.currentVariation.prices.specialOffer;
          },
          variationGraduatedPrice() {
            return this.$store.getters[`${this.itemId}/variationGraduatedPrice`];
          },
          variationTotalPrice() {
            return this.$store.getters[`${this.itemId}/variationTotalPrice`];
          },
      // <!-- item-price -->
      // item-availabilty
        availability() {
          const currentVariation = this.$store.getters[`${this.itemId}/currentItemVariation`];
          return currentVariation && currentVariation.variation && currentVariation.variation.availability;
        },
        classes() {
          return [
            "availability-" + (this.availability && this.availability.id)
          ];
        },
        name() {
          return this.availability && this.availability.names && this.availability.names.name;
        },
      // item-availability
        isFireworkPickupOnly()
        {
          return App.configKS.item.fireworkOnlyPickup == "true" ? true : false;
        },
        getShowStock()
        {
          return this.showStock;
        },
        getStockIndicator()
        {
          let indicator = 1;
          if(this.currentVariation.stock.net < 10 && this.currentVariation.stock.net > 0)
          {
            indicator =  2;
          }
          else if (this.currentVariation.stock.net <= 0)
          {
            indicator =  3;
          }
          return indicator;
        },
        hasShippingCostsCategoryId()
        {
            return App.config.global.shippingCostsCategoryId > 0;
        },
        variationGroupedProperties()
        {
            return this.$store.getters[`${this.itemId}/variationGroupedProperties`];
        },
        variationMissingProperties()
        {
            return this.$store.getters[`${this.itemId}/variationMissingProperties`];
        },
        currentVariation() {
            return get(this.$store.state, `items[${this.itemId}].variation.documents[0].data`);
        },
        isVariationSelected() {
            return get(this.$store.state, `items[${this.itemId}].variationSelect.isVariationSelected`);
        },
        attributes() {
            return get(this.$store.state, `items[${this.itemId}].variationSelect.attributes`);
        },
        units() {
            return get(this.$store.state, `items[${this.itemId}].variationSelect.units`);
        }
    },

    created()
    {
        this.$store.dispatch("initVariation", this.itemData);
        this.$store.commit(`${this.itemId}/setPleaseSelectVariationId`, this.pleaseSelectOptionVariationId);
    },
  mounted()
  {
    this.$nextTick(() =>
    {
      this.$store.dispatch(`${this.itemId}/variationSelect/setVariationSelect`, {
        itemId:             this.itemId,
        attributes:         this.attributesData,
        variations:         this.variations,
        initialVariationId: this.currentVariation.variation.id,
        isPleaseSelectOption: this.initPleaseSelectOption,
        afterKey:           this.afterKey
      });
    })

    document.addEventListener("onVariationChanged", () => this.hydrateChildren(this.$children));
  },

  methods: {
    // iterate recursively the children components and call their hydrate method, if it is a lazy-hydrate component
    hydrateChildren(nodes)
    {
      nodes.forEach(component => {
        if (component.$options.name === "lazy-hydrate")
        {
          component.hydrate();
        }
        else if (component.$children.length)
        {
          this.hydrateChildren(component.$children);
        }
      })
    }
  }
}
</script>
