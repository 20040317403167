var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showBanner & _vm.isActive,
            expression: "showBanner & isActive"
          }
        ],
        staticClass: "notify row"
      },
      [
        _c("div", {
          staticClass: "message text",
          domProps: { innerHTML: _vm._s(_vm.dynContent) }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "button-container" }, [
          _c(
            "button",
            {
              staticClass: "close close-top",
              attrs: { type: "button", "aria-label": "Close" },
              on: {
                click: function($event) {
                  return _vm.setStorage()
                }
              }
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("x")])]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }