var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "position-relative" }, [
    _vm.isLoggedIn || _vm.twigIsLoggedIn
      ? _c("div", { staticClass: "dropdown" }, [
          _c(
            "a",
            {
              staticClass: "dropdown-toggle",
              attrs: {
                href: "#",
                id: "accountMenuList",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-user mr-0 mr-sm-1",
                attrs: { "aria-hidden": "true" }
              }),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "d-xs-none small" }, [
                _vm._v("Hallo " + _vm._s(_vm.usernameToShow))
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-menu small m-0 p-0 mw-100" }, [
            _c(
              "div",
              {
                staticClass: "list-group",
                attrs: { "aria-labelledby": "accountMenuList" }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "list-group-item small",
                    attrs: { href: _vm.myAccountUrl }
                  },
                  [
                    _c("i", { staticClass: "fa fa-user" }),
                    _vm._v("  Mein Konto")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [{ name: "logout", rawName: "v-logout" }],
                    staticClass: "list-group-item small",
                    attrs: { href: "#" }
                  },
                  [
                    _c("i", { staticClass: "fa fa-sign-out" }),
                    _vm._v(" Ausloggen")
                  ]
                )
              ]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isLoggedIn && !_vm.twigIsLoggedIn
      ? _c("div", [
          _c(
            "a",
            {
              attrs: {
                href: _vm.isLogin ? "javascript:void(0)" : "/login?g=0",
                "aria-label": _vm.$translate("Ceres::Template.login")
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-user mr-0 mr-sm-1",
                attrs: { "aria-hidden": "true" }
              }),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "d-none d-sm-inline" }, [
                _vm._v("Anmelden")
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }